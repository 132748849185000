import { Controller } from 'stimulus'

export default class extends Controller {

  swap(event) {
    this.observedJobController.disconnect()
    this.element.action = this.element.action.replace('.json', '.js')
    $(this.element).on("ajax:complete", this.swapBack)

  }

  swapBack = () => {
    $(this.element).off("ajax:complete", this.swapBack)
    this.element.action = this.element.action.replace('.js', '.json')
    this.observedJobController.connect()
  }

  get observedJobController() {
    return this.application.getControllerForElementAndIdentifier(this.element, 'observed-job')
  }
}

