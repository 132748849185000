import { Controller } from 'stimulus'

export default class extends Controller {

  connect () {
    // $(this.element).on("ajax:send", this.send)
    // $(this.element).on("ajax:complete", this.complete)
  }

  disconnect () {
    // $(this.element).off("ajax:send", this.send)
    // $(this.element).off("ajax:complete", this.complete)
  }

  send = (event) => {
    // if (event.target != this.element) return
    // if (Turbolinks.controller.adapter.progressBar) {
    //   Turbolinks.controller.adapter.progressBar.setValue(0)
    //   Turbolinks.controller.adapter.progressBar.show()
    // }
    // document.activeElement.blur()
    // Turbolinks.dispatch('turbolinks:click', event)
  }

  complete =  (event, xhr) => {
    // if (Turbolinks.controller.adapter.progressBar) {
    //   Turbolinks.controller.adapter.progressBar.hide()
    //   Turbolinks.controller.adapter.progressBar.setValue(100)
    // }
    // if (xhr.status >= 500 || xhr.status === 304 && xhr.status !== 422) return

    // if (!xhr.getResponseHeader('Location')) {
    //   let currentSnapshot = Turbolinks.Snapshot.fromHTMLElement(document)
    //   let newSnapshot     = Turbolinks.Snapshot.wrap(xhr.responseText)
    //   Turbolinks.controller.currentVisit = Turbolinks.controller.createVisit(window.location.href, 'replace', {})
    //   Turbolinks.SnapshotRenderer.render(Turbolinks.controller, this.rendered, currentSnapshot, newSnapshot)

    //   // if (event.target.method.toLowerCase() === 'get' && xhr.status >= 200 && xhr.status < 300) {
    //   //   window.history.pushState({ turbolinks: true }, '', xhr.responseURL)
    //   // }
    // }
  }

  rendered () {
    // Turbolinks.dispatch('turbolinks:load')
  }
}

