import React from "react";

export default function CheckboxLabel({ label, checked, toggle }) {
  return (
    <div className={`filter-styled checkbox ${checked ? "active" : ""}`}>
      {label}
      <input
        type="checkbox"
        checked={checked}
        onChange={() => toggle(!checked)}
      />
    </div>
  );
}
