import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import CheckboxDropdown from "../../ui/checkbox-dropdown";
import apiFetch from "../../../modules/abortable-api-fetch";
import AbortController from "abort-controller";

function ProductSearchFilters({
  selectedListings,
  setSelectedListings,
  selectedCategories,
  setSelectedCategories,
  selectedSubcategories,
  setSelectedSubcategories,
  selectedWarehoused,
  setSelectedWarehoused,
  attrSearch,
  setAttrSearch,
  numberSearch,
  setNumberSearch,
  sortBy,
  setSortBy,
  searchParams,
  subcategories,
  resetFilters,
  match
}) {
  const [listings, setListings] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [categories, setCategories] = useState([]);

  const abortController = new AbortController();

  useEffect(() => {
    apiFetch(
      abortController.signal,
      `/${match.params.account_id}/warehouses.json`
    ).then(json => setWarehouses(json.warehouses));

    apiFetch(
      abortController.signal,
      `/${match.params.account_id}/listings.json`
    ).then(json => setListings(json.listings));

    apiFetch(
      abortController.signal,
      `/${match.params.account_id}/categories.json`
    ).then(json => setCategories(json.categories));
  }, []);

  return (
    <div id="filter-options" className="filters material">
      <div className="row">
        <div className="col-lg-5 col-md-5 bootstrap-one-third-padding-right col-sm-12">
          <input
            type="text"
            className="large form-control"
            style={{
              marginBottom: "10px",
              width: "100%",
              height: "40px",
              fontSize: "24px"
            }}
            placeholder="Product Attribute"
            value={attrSearch}
            onChange={e => setAttrSearch(e.target.value)}
          />
        </div>

        <div className="col-lg-5 col-md-5 bootstrap-one-third-padding-right col-sm-12">
          <input
            type="text"
            className="large form-control"
            placeholder="Tag Number"
            style={{
              marginBottom: "10px",
              width: "100%",
              height: "40px",
              fontSize: "24px"
            }}
            value={numberSearch}
            onChange={e => setNumberSearch(e.target.value)}
          />
        </div>

        <div className="col-lg-2 col-md-2 col-sm-12">
          <a
            className="filter-reset-button button small"
            style={{
              fontSize: "14px",
              marginBottom: "0px",
              width: "100%"
            }}
            onClick={() => resetFilters()}
          >
            <i className="fa fa-undo"></i> Reset Filters
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 bootstrap-one-third-padding-right col-sm-12">
          <CheckboxDropdown
            multiple
            label="Listed at"
            selectedIds={selectedListings}
            setSelectedIds={setSelectedListings}
            options={listings}
            noneSelectedText="Nothing Selected"
          />
          <CheckboxDropdown
            multiple
            label="Warehoused at"
            selectedIds={selectedWarehoused}
            setSelectedIds={setSelectedWarehoused}
            options={warehouses}
            noneSelectedText="Nothing Selected"
          />

          <CheckboxDropdown
            multiple
            label="Categories"
            selectedIds={selectedCategories}
            setSelectedIds={setSelectedCategories}
            options={categories}
            noneSelectedText="Nothing Selected"
          />

          {subcategories.length > 0 ? (
            <CheckboxDropdown
              multiple
              label="Subcategories"
              selectedIds={selectedSubcategories}
              setSelectedIds={setSelectedSubcategories}
              options={subcategories}
              noneSelectedText="Nothing Selected"
            />
          ) : null}

          <div className="filter-styled large inline inline-block">
            <div className="filter-label">Sort By</div>
            <select name="sort_by" id="sort_by" className="form-control" onChange={e => setSortBy(e.target.value)} value={sortBy}>
              <option value='last_used_at ASC'>Oldest Use</option>
              <option value='last_used_at DESC'>Newest Use</option>
              <option value='product_times_used ASC'>Least Used</option>
              <option value='product_times_used DESC'>Most Used</option>
            </select>
          </div>

          <button
            onClick={() => {
              Turbo.visit(
                `/${match.params.account_id}/reports/products.csv?page=0&${searchParams}`
              );
            }}
            className="button "
            style={{
              fontSize: "12px",
              padding: "8px 16px",
              marginBottom: "10px !important"
            }}
          >
            Download as csv
          </button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ProductSearchFilters);
