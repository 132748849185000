import { Controller } from 'stimulus'

export default class extends Controller {

  connect () {
    try {
      if (!(window.webkit && window.webkit.messageHandlers.scanMode) && !(window.android && window.android.scanMode)) {
        this.element.style.display = 'none'
      }
    } catch (e) {
      this.element.style.display = 'none'
    }
  }

  openSettings () {
    if (window.webkit && window.webkit.messageHandlers.openSettings) {
      window.webkit.messageHandlers.openSettings.postMessage(null)
    }
    if (window.android && window.android.openSettings) {
      window.android.openSettings()
    }
  }
}
