import React from "react";
import { Switch, Route } from "react-router-dom";

import WarehouseReport from "./reports/warehouse";
import ProductsReport from "./reports/products";
import ManifestReport from "./reports/manifest";

export default function App() {
  return (
    <Switch>
      <Route
        exact
        path="/:account_id/pages/warehouse_report/:id?"
        component={WarehouseReport}
      />

      <Route
        exact
        path="/:account_id/pages/products_report"
        component={ProductsReport}
      />

      <Route
        exact
        path="/:account_id/reports/warehouses/:id/manifest"
        component={ManifestReport}
      />
    </Switch>
  );
}
