import fetch from "isomorphic-fetch";
require("es6-promises");

function fetchApi(path, method, data) {
  const options = {};
  const headers = {
    "Content-Type": "application/json",
    Accepts: "application/json"
  };
  if (!method) method = "GET";
  if (method.toUpperCase() !== "GET") {
    options["body"] = JSON.stringify(data);
    headers["X-CSRF-TOKEN"] = data.authenticty_token;
  }

  return fetch(`//${location.host}/${path}`, {
    method: method.toUpperCase(),
    headers,
    ...options
  }).then(response => response.json());
}

export default fetchApi;
