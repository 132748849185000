import React, { useState, useEffect } from "react";
import { geocodeByAddress } from "react-places-autocomplete";
import moment from "moment";
import * as Datetime from "react-datetime";

// import LocationSearchInput  './location-search-input'

import fetchApi from "../../modules/api-fetch";

function ValidationMessage(props) {
  if (!props.valid) {
    return <div className="error-msg">{props.message}</div>;
  }
  return null;
}

function validationErrors(setState, valid, msg) {
  setState(prev => ({
    ...prev,
    errorMsg: valid
      ? prev.errorMsg.filter(err => err !== msg)
      : prev.errorMsg.find(_msg => msg === _msg)
      ? prev.errorMsg
      : [...prev.errorMsg, msg]
  }));
}

export default function EventCalendarForm(props) {
  console.dir(props);
  const initialEventState = {
    name: props.name || "",
    address: props.address || "",
    occursAt: props.occursAt
      ? moment(props.occursAt, "YYYY-MM-DDTHH:mm:ssZ")
      : "",
    description: props.description || "",
    durationInMinutes: props.durationInMinutes || 30,
    allDay: props.allDay || false,
    eventTypeId: props.eventTypeId || -1,
    errorMsg: []
  };

  const [state, setState] = useState(initialEventState);
  const {
    name,
    address,
    occursAt,
    description,
    durationInMinutes,
    allDay,
    eventTypeId,
    errorMsg
  } = state;
  const {
    id,
    accountHandle,
    listingId,
    authenticityToken,
    close,
    success
  } = props;

  const [eventTypes, setEventTypes] = useState([]);

  useEffect(() => {
    fetchApi(`${accountHandle}/event_types`).then(json => {
      setEventTypes(json.eventTypes);
      setState(prevState => ({
        ...prevState,
        eventTypeId: json.eventTypes[0].id
      }));
    });
  }, []);

  useEffect(() => {
    const valid = state.name && state.name.length >= 3;
    validationErrors(
      setState,
      valid,
      "Must be at least 3 characters in length"
    );
  }, [state.name]);

  useEffect(() => {
    const valid = typeof state.occursAt == "object" && state.occursAt.isValid();
    validationErrors(setState, valid, "You must select a date-time.");
  }, [state.occursAt]);

  function handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;
    setState(prev => ({
      ...prev,
      [name]: value
    }));
  }

  function handleDatePick(value) {
    setState(prev => ({
      ...prev,
      occursAt: value
    }));
  }

  function geocodeAddress(e) {
    geocodeByAddress(e.target.value)
      .then(results => {
        e.target.value = results[0];
      })
      .catch(error => console.error("Error", error));

    handleChange(e);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const method = id === undefined ? "post" : "patch";
    const url =
      method === "post"
        ? `${accountHandle}/listings/${listingId}/listing_events`
        : `${accountHandle}/listings/${listingId}/listing_events/${id}`;

    fetchApi(url, method, {
      authenticity_token: authenticityToken,
      listing_event: {
        name,
        description,
        occurs_at: occursAt,
        duration_in_minutes: durationInMinutes,
        all_day: allDay,
        event_type_id: eventTypeId
      }
    }).then(json => {
      if (json.errors) {
        alert("there were problems with your event info");
        console.dir(json.errors);
        return;
      }

      setState(initialEventState);
      success(json.listingEvent);
    });
  }

  return (
    <form onSubmit={handleSubmit} className="event-calendar-form">
      <div className="row">
        <div className="col-md-12">
          <input
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
            placeholder="Name"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <Datetime
            input={false}
            open={true}
            onChange={handleDatePick}
            name="occursAt"
            defaultValue={occursAt}
            closeOnSelect={true}
            timeFormat={allDay ? false : "h:mm a"}
          />
        </div>

        <div className="col-sm-6">
          <div className="col-sm-12">
            <select
              className="durationCheckBox"
              name="durationInMinutes"
              value={durationInMinutes}
              onChange={handleChange}
              disabled={allDay}
            >
              <option value="30">30 minutes</option>
              <option value="60">1 hour</option>
              <option value="90">90 minutes</option>
              <option value="120">2 hours</option>
            </select>
            <input
              type="checkbox"
              checked={allDay}
              onChange={e => {
                const allDay = e.target.checked;
                setState(prevState => ({
                  ...prevState,
                  allDay
                }));
              }}
            />{" "}
            All Day
          </div>

          <div className="col-sm-12">
            <select
              name="event_type"
              value={eventTypeId}
              onChange={e => {
                const eventTypeId = e.target.value;
                setState(prevState => ({
                  ...prevState,
                  eventTypeId
                }));
              }}
            >
              {eventTypes.map(et => (
                <option value={et.id}>{et.name}</option>
              ))}
            </select>
          </div>

          <div className="col-sm-12">
            <textarea
              name="description"
              placeholder="Event details"
              value={description}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <button
            disabled={errorMsg.length > 0}
            onClick={handleSubmit}
            className="button btn-primary"
          >
            <i className="fa fa-calendar-check-o" aria-hidden="true">
              {" "}
            </i>{" "}
            {id !== null ? "Save" : "Create"} Event
          </button>
          <button className="button btn-secondary" onClick={close}>
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
}
