import React , { Fragment  } from 'react'



export default function Contact({
  linkContactToListing,
  unlinkContactToListing,
  isListingContact,
  deleteContact,
  contact,
  setShowForm
}) {
  return <Fragment>
  
  <input
    type="checkbox"
    value={contact.id}
    id="contactCheck"
    name="listing[listing_contacts_attributes][][contact_id]"
    onChange={(e) => e.target.checked
    ? linkContactToListing(contact)
    : unlinkContactToListing(contact) }
    defaultChecked={isListingContact(contact.id)}/>
    <label htmlFor="contactCheck">&nbsp;
      { contact.name } - { contact.contact_type_label} &nbsp;
 
      <a  onClick={() => setShowForm(contact)}>Edit</a> |
      <a  onClick={() => deleteContact(contact)}>Delete</a>

  </label>
</Fragment>
}
