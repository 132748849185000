import React from "react";

export default function ProductWarehouseSectionOverride({
  id,
  inheritedFrom,
  warehouseSectionOverride,
}) {
  return (
    <div className="col-sm-4">
      <h5 className="subtle-header">Default warehouse section</h5>

      <div
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          marginRight: "10px",
          marginTop: "5px",
        }}
      >
        <div
          style={{
            color: "#8A8A8A",
            borderRadius: "5px",
            border: "2px solid #eeeeee",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "inline-block",
              fontSize: "16px",
              paddingLeft: "3px",
              paddingRight: "3px",
              paddingTop: "4px",
              paddingBottom: "3px",
              verticalAlign: "top",
            }}
          >
            {warehouseSectionOverride}
          </div>
          <div
            style={{
              fontSize: "10px",
              textAlign: "center",
              borderTop: "2px solid #eeeeee",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            Inherited from {inheritedFrom}
          </div>
        </div>
      </div>
    </div>
  );
}
