import React from 'react'

export default function ContactType({ contactTypes, selectedContactType, setSelectedContactType }) {
  return <div style={{ margin: 10 }}
  >
    <h4 className="data-label">Contact Type</h4>
      <div className=''> 
      <select
        className='form-control'
        onChange={(e) => {
          setSelectedContactType(contactTypes.find(contactType => contactType.id === parseInt(e.target.value)))
        }}
        value={selectedContactType && selectedContactType.id}
        name="contact[contact_type_id]"
      >
        <option>Select type</option>
        {
          contactTypes.map(contactType => <option
              key={contactType.id}
              value={contactType.id}
            >
              { contactType.label }
            </option>)
        }
      </select>
    </div>
  </div>
}