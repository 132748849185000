import { Controller } from 'stimulus'

export default class extends Controller {

  trigger(event) {
    event.stopPropagation()
    window.parent.open_modal(event.currentTarget.dataset.path)
  }

}
