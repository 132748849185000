import { Controller } from 'stimulus'
import Google from 'mixins/google'

export default class extends Google(Controller) {

  static targets = [ "address", 'city', 'state', 'postal', 'country', 'addressContainer', 'addressContainer', 'addressPart' ]

  connect() {
    this.googleConnect()
    if (this.addressTarget.value != '' || this.cityTarget.value != '' || this.stateTarget.value != '' || this.postalTarget.value != '' || this.countryTarget.value != '') {
      this.reveal()
    }
  }

  googleConnected() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, {
      fields: ["address_components", "geometry"],
      types: ["address"],
    });
    this.autocomplete.addListener("place_changed", this.fillInAddress);
  }

  reveal() {
    for (let part of this.addressPartTargets) {
      part.style.display = ''
      part.classList.remove('dn')
    }
    if (this.hasAddressContainerTarget){
      this.addressContainerTarget.classList.remove('col-md-12')
      this.addressContainerTarget.classList.add('col-md-7')
    }
  }

  fillInAddress = () => {
    const place = this.autocomplete.getPlace()
    let address1 = ""
    let postcode = ""

    for (const component of place.address_components) {
      const componentType = component.types[0]

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`
          break
        }

        case "route": {
          address1 += component.short_name
          break
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`
          break
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`
          break
        }
        case "locality":
          this.cityTarget.value = component.long_name
          break

        case "administrative_area_level_1": {
          this.stateTarget.value = component.short_name
          break
        }
        case "country":
          this.countryTarget.value = component.short_name
          break
        }
      }
    this.addressTarget.value = address1
    this.postalTarget.value = postcode
  }
}

