import React from "react";
import Dropdown from "react-select";

export default function Filters({
  rfidTagFilter,
  setRfidTagFilter,
  productFilter,
  setProductFilter,
  categoryFilter,
  setCategoryFilter,
  categories,
  subcategories,
}) {
  const styles = {
    padding: "8px",
    border: "1px solid lightgrey",
    borderRadius: "3px",
    width: "100%",
  };
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid lightgrey",
        borderRadius: "3px",
        padding: "1rem",
        margin: "3rem 0",
        boxSizing: "box-sizing",
      }}
    >
      <div style={{ flex: "1" }}>
        <label style={{ display: "block" }}>
          RFID Tag <small>(exact match)</small>
        </label>
        <input
          style={styles}
          type="text"
          value={rfidTagFilter}
          onChange={(e) => setRfidTagFilter(e.target.value)}
          placeholder="Filter by rfid tag"
        />
      </div>

      <div style={{ flex: "1" }}>
        <label style={{ display: "block" }}>
          Product name <small>(substring match)</small>
        </label>
        <input
          style={styles}
          type="text"
          value={productFilter}
          onChange={(e) => setProductFilter(e.target.value)}
          placeholder="Filter by product name"
        />
      </div>

      <div style={{ flex: "1" }}>
        <label style={{ display: "block" }}>Category</label>
        <Dropdown
          style={styles}
          isMulti
          options={categories.map((category) => ({
            value: category.id,
            label: category.name,
          }))}
          onChange={(val) => setCategoryFilter(val || [])}
          value={categoryFilter}
        />
      </div>
    </div>
  );
}
