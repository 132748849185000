import React from 'react'

export default function FilterCompanies({ companies, filteredCompany, setFilteredCompany }) {
  return <div style={{ margin: 10 }}
  >
    <h4 className="data-label">Companies</h4>
      <div className=''> 
      <select
        className='form-control'
        onChange={(e) => {
          setFilteredCompany(companies.find(company => company.id === parseInt(e.target.value)))
        }}
        value={filteredCompany && filteredCompany.id}
      >
        <option>Filter companies</option>
        {
          companies.map(company => <option
              key={company.id}
              value={company.id}
            >
              { company.name }
            </option>)
        }
      </select>
    </div>
  </div>
}