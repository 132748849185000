import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'swipe', 'slide', 'bg', 'close', 'hide' ]

  toggle() {
    if (this.element.classList.contains('fixed')) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    for (let scroll of document.getElementsByClassName('smooth-scroll')) {
      scroll.classList.replace('smooth-scroll', 'smooth-scroll-placeholder')
    }
    this.closeTarget.classList.replace('dn', 'flex')
    this.element.classList.add('fixed', 'top-0', 'left-0', 'w-100', 'h-100', 'z-max')
    this.bgTarget.classList.add('bg-black-90', 'absolute')
    this.swipeTarget.classList.remove('aspect-ratio', 'aspect-ratio--6x4', 'zoom-in')
    this.swipeTarget.classList.add('w-100', 'h-100', 'zoom-out')
    this.resize()
    for (let slide of this.slideTargets) {
      slide.classList.remove('object-fit-cover')
      slide.classList.add('object-fit-contain')
    }
    for (let hide of this.hideTargets) {
      hide.classList.add('dn')
    }
  }

  hide () {
    for (let scroll of document.getElementsByClassName('smooth-scroll-placeholder')) {
      scroll.classList.replace('smooth-scroll-placeholder', 'smooth-scroll')
    }
    this.closeTarget.classList.replace('flex', 'dn')
    this.element.classList.remove('fixed', 'top-0', 'left-0', 'w-100', 'h-100', 'z-max')
    this.bgTarget.classList.remove('bg-black-90', 'absolute')
    this.swipeTarget.classList.add('aspect-ratio', 'aspect-ratio--6x4', 'zoom-in')
    this.swipeTarget.classList.remove('w-100', 'h-100', 'zoom-out')
    this.bgTarget.style.height = ''
    for (let slide of this.slideTargets) {
      slide.classList.add('object-fit-cover')
      slide.classList.remove('object-fit-contain')
    }
    for (let hide of this.hideTargets) {
      hide.classList.remove('dn')
    }
  }

  resize() {

    if (this.element.classList.contains('fixed')) {
      window.requestAnimationFrame(() => {
        this.bgTarget.style.height = `${ window.innerHeight }px`
      })
    }
  }
}
