import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { BeatLoader } from "react-spinners";
import { withRouter } from "react-router-dom";
import ProductRow from "./product-row";

function ProductTable({
  match,
  warehouseId,
  categoryId,
  rent,
  sale,
  value,
  count
}) {
  const [loaded, setLoaded] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch(
      `//${window.location.host}/${match.params.account_id}/warehouse_info/${warehouseId}/category_products/${categoryId}`
    )
      .then(response => response.json())
      .then(json => {
        setProducts(json);
      });
  }, []);

  useEffect(() => {
    setLoaded(products.length > 0);
  }, [products]);

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Tag #</th>
          <th>Purchase $</th>
          <th>Rental $</th>
          <th>Sale $</th>
          <th># Times Used</th>
          <th>Days Since First Staged</th>
          <th>Days of Staging</th>
          <th>% Time Used</th>
        </tr>
      </thead>
      <tbody>
        {!loaded ? (
          <tr>
            <td colSpan={9}>
              <BeatLoader color={"#4db3bf"} />
            </td>
          </tr>
        ) : (
          products.map(product => <ProductRow {...product} />)
        )}

        <tr>
          <td>
            <strong> Summary: </strong>
          </td>
          <td>
            <strong> --- </strong>
          </td>

          <td>
            <strong>
              <NumberFormat
                value={value}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                fixedDecimalScale={true}
                decimalScale={2}
              />
            </strong>
          </td>
          <td>
            <strong> --- </strong>
          </td>
          <td>
            <strong>
              <NumberFormat
                value={sale}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                fixedDecimalScale={true}
                decimalScale={2}
              />
            </strong>
          </td>
          <td>
            <strong> --- </strong>
          </td>
          <td>
            <strong> --- </strong>
          </td>
          <td>
            <strong> --- </strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default withRouter(ProductTable);
