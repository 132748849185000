import { Controller } from 'stimulus'

export default class extends Controller {

  connect () {
    $(this.element).on("ajax:beforeSend", this.send)
  }

  disconnect () {
    $(this.element).off("ajax:beforeSend", this.send)
  }

  send = (event, xhr, settings) => {
    if (event.target != this.element) return
    event.preventDefault()
    event.stopPropagation()
    xhr.abort()
    Turbo.visit(settings.url)
  }
}
