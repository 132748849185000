import React from 'react'
import './styles.css'

export default function Modal({ children, visible, width }) {
  return <div
    className="modal-window"
    style={{
      width: width,
      display: visible ? 'block' : 'none',

    }}
  >
    { children }
  </div>
}