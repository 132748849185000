import React, { useEffect } from "react";

export default function ScrollToCategory({ categories }) {
  function scrollTo(id) {
    document.getElementById(`category_${id}`).scrollIntoView();
  }

  return (
    <div>
      Scroll to: &nbsp;
      <select onChange={e => scrollTo(e.target.value)}>
        {categories.map(category => {
          return (
            <option key={category.category_id} value={category.category_id}>
              {category.category_name}
            </option>
          );
        })}
      </select>
      <button
        onClick={() => document.getElementById("top-of-page").scrollIntoView()}
      >
        Back to top
      </button>
    </div>
  );
}
