import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ScrollToCategory from "./scroll-to-category";
import ProductTable from "./product-table";

function WarehouseInventory({ match, warehouseId }) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch(
      `//${window.location.host}/${match.params.account_id}/warehouse_info/${warehouseId}/categories`
    )
      .then(response => response.json())
      .then(json => {
        setCategories(json);
      });
  }, [warehouseId]);

  return (
    <div>
      <h2>Inventory Details:</h2>

      {categories.map(category => (
        <div id={`category_${category.category_id}`}>
          <h3>{category.category_name}</h3>
          <ScrollToCategory categories={categories} />
          <div>
            <div>
              {category.tags_count} tagged products found | Rent:{" "}
              {category.rental_percent}% | Sale:
              {category.sale_percent}%
            </div>
            <ProductTable
              warehouseId={warehouseId}
              categoryId={category.category_id}
              count={category.tags_count}
              value={category.working_cost_category}
              rent={category.rental_total_category}
              sale={category.sale_total_category}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default withRouter(WarehouseInventory);
