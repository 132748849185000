import React, { useState } from 'react'

export default function SelectCompany({ companies, selectedCompany, setShowCreateCompany, setSelectedCompany }) {
  
  return  <div style={{ margin: 10 }}

  >
  <h4 className="data-label">Associated Company</h4>

      <select
      className='form-control'
      defaultValue={selectedCompany && selectedCompany.id}
        onChange={(e) => {
          if(e.target.value === 'new') {
            setShowCreateCompany(true)
          } else {
            setSelectedCompany(companies.find(company => company.id === parseInt(e.target.value)))
          }
        }}
        name="contact[company_id]"
      >
        <option>Select a brokerage firm</option>
        <option value="new">Create a new brokerage firm</option>
        {
          companies.map(company => <option
              key={company.id}
              value={company.id}
            >
              { company.name }
            </option>)
        }
      </select>
    </div>
}