import { Controller } from 'stimulus'
import { Calendar } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import tippy from 'tippy.js';

export default class extends Controller {

  connect () {
    this.calendar = new Calendar(this.element, {
      plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin ],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek,timeGridThereWeeks'
      },
      // initialDate: this.events[0].start,
      initialView: 'dayGridMonth',
      views: {
        timeGridThereWeeks: {
          type: 'listWeek',
          duration: { days: 21 },
          buttonText: '3 weeks'
        }
      },
      eventDidMount: function(arg) {
        if (arg.event.extendedProps.description) {
          tippy(arg.el, {
            content: arg.event.extendedProps.description,
          });
        }
      },
      navLinks: true,
      editable: true,
      dayMaxEvents: true,
      events: this.events,
      eventClick: this.click
    });

    this.calendar.render();
  }

  click(info) {
    info.jsEvent.preventDefault();
    Turbo.visit(info.event.url)
  }

  get events() {
    return JSON.parse(this.element.dataset.events)
  }
}
