import React, { useState, useEffect } from "react";

import fetchApi from "../../modules/api-fetch";
import csrfToken from "../../modules/csrf-token";

import FilterCompanies from "./filter-companies";
import Contact from "./contact";
import Form from "./form";

import "./styles.scss";

export default function NewContactForm({ account_id, listing_id }) {
  const initialContactState = {
    email: "",
    phone: "",
    address: "",
    name: ""
  };

  const [listingContacts, setListingContacts] = useState([]);
  const [contactTypes, setContactTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedContactType, setSelectedContactType] = useState("");
  const [filteredCompany, setFilteredCompany] = useState(null);
  const [filteredContactName, setFilteredContactName] = useState(null);
  const [showCreateCompany, setShowCreateCompany] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [formValues, setFormValues] = useState(initialContactState);
  const [showForm, _setShowForm] = useState(null); // null -> dont show, contact - show
  const [companyErrors, setCompanyErrors] = useState({
    name: []
  });
  const [contactErrors, setContactErrors] = useState({
    company_id: [],
    name: []
  });

  function setShowForm(contact) {
    if (contact === undefined || contact === null) {
      _setShowForm(null);
      return;
    }
    _setShowForm(contact);

    setSelectedContactType({
      ...contactTypes.find(ct => ct.label === contact.contactTypeLabel)
    });

    setSelectedCompany({ ...companies.find(c => c.id === contact.company_id) });
    setFormValues(
      Object.keys(formValues).reduce((acc, key) => {
        acc[key] = contact[key];
        return acc;
      }, {})
    );
  }

  useEffect(() => {
    fetchApi(`${account_id}/contact_types.json`, "GET").then(json => {
      setContactTypes(json);
    });

    fetchApi(`${account_id}/companies.json`, "GET").then(json =>
      setCompanies(
        json.companies.map(company => {
          return {
            ...company,
            contacts: sortByName(company.contacts)
          };
        })
      )
    );
    fetchApi(`${account_id}/listings/${listing_id}/contacts.json`, "GET").then(
      json => {
        setListingContacts(json.listingContacts);
      }
    );
  }, []);

  function sortByName(array) {
    return array.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  function deleteContact(contact) {
    fetchApi(`${account_id}/contacts/${contact.id}`, "DELETE", {
      authenticty_token: csrfToken(),
      listing_id: listing_id,
      company_id: contact.companyId
    }).then(json => {
      setCompanies(prevState => {
        return prevState.map(company => ({
          ...company,
          contacts: company.contacts.filter(
            _contact => _contact.id !== contact.id
          )
        }));
      });

      setFormValues(initialContactState);
      setSelectedContactType(null);
      setSelectedCompany(null);
    });
  }

  function saveContact(id) {
    const [url, method] = id
      ? [`${account_id}/contacts/${id}`, "PATCH"]
      : [`${account_id}/contacts`, "POST"];

    fetchApi(url, method, {
      authenticty_token: csrfToken(),
      contact: {
        ...formValues,
        contact_type_id: selectedContactType.id
      },
      company_id: selectedCompany.id
    }).then(json => {
      if (json.errors) {
        setContactErrors(json.errors);
        return;
      }

      // check to see if the record is new or edited
      // if edited and the company id changed, remove from previous company state collection
      setCompanies(prevState =>
        prevState.map(company => {
          const withoutContact = company.contacts.filter(
            cont => cont.id !== json.contact.id
          );
          return {
            ...company,
            contacts:
              json.contact.companyId === company.id
                ? [...withoutContact, json.contact]
                : withoutContact
          };
        })
      );

      setFormValues(initialContactState);
      setSelectedContactType(prev => ({ ...prev }));
      setSelectedCompany(prev => ({ ...prev }));
      setShowForm(null);
    });
  }

  function createCompany(name) {
    if (
      companies.find(
        company => company.name.toLowerCase() === name.toLowerCase()
      )
    ) {
      setCompanyErrors({
        name: ["already exists"]
      });
      return;
    }

    fetchApi(`${account_id}/companies`, "POST", {
      authenticty_token: csrfToken(),
      company: {
        name: name
      }
    }).then(json => {
      if (json.errors) {
        setCompanyErrors(json.errors);
        console.log(json.errors);
        return;
      }

      setCompanies(prevState => [...prevState, json.company]);
      setSelectedCompany(json.company);
      setShowCreateCompany(false);
    });
  }

  function deleteCompany(company) {
    fetchApi(`${account_id}/companies/${company.id}`, "DELETE", {
      authenticty_token: csrfToken(),
      company_id: company.id
    }).then(json => {
      setCompanies(prevState =>
        prevState.filter(_company => company.id !== _company.id)
      );

      setSelectedCompany(null);
    });
  }

  function isListingContact(contact_id) {
    return listingContacts.find(lc => contact_id === lc.contact_id);
  }

  function filterByCompanyOrContactName(companies) {
    const filteredCompanies = filteredCompany
      ? companies.filter(company => company.name === filteredCompany.name)
      : companies;

    if (filteredContactName) {
      return filteredCompanies.map(company => ({
        ...company,
        contacts: company.contacts.filter(contact => {
          const re = new RegExp(`${filteredContactName.toLowerCase()}`);

          return re.test(contact.name.toLowerCase());
        })
      }));
    } else {
      return filteredCompanies;
    }
  }

  function linkContactToListing(contact) {
    fetchApi(`${account_id}/listings/${listing_id}/contacts`, "POST", {
      authenticty_token: csrfToken(),
      contact_id: contact.id
    }).then(json => {
      setListingContacts(prevState => [...prevState, json]);
    });
  }

  function unlinkContactToListing(contact) {
    fetchApi(`${account_id}/listings/${listing_id}/contacts`, "DELETE", {
      authenticty_token: csrfToken(),
      contact_id: contact.id
    }).then(json => {
      setListingContacts(prevState =>
        prevState.filter(lc => lc.contact_id !== contact.id)
      );
    });
  }

  return (
    <div>
      <button
        type="button"
        className="button btn-primary"
        onClick={() => setShowForm(initialContactState)}
      >
        Create new
      </button>
      <div className="col-md-12">
        {showForm === null ? null : (
          <Form
            contactTypes={contactTypes}
            selectedContactType={selectedContactType}
            setSelectedContactType={setSelectedContactType}
            setFormValues={setFormValues}
            formValues={formValues}
            saveContact={() => saveContact(showForm && showForm.id)}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            createCompany={createCompany}
            setShowCreateCompany={setShowCreateCompany}
            companyErrors={companyErrors}
            companies={companies}
            showCreateCompany={showCreateCompany}
            contact={showForm}
          />
        )}
      </div>

      <hr />

      <FilterCompanies
        companies={companies}
        filteredCompany={filteredCompany}
        setFilteredCompany={setFilteredCompany}
        filteredContactName={filteredContactName}
        setFilteredContactName={setFilteredContactName}
      />

      <div style={{ display: "flex", flexWrap: "wrap", padding: "1em" }}>
        {filterByCompanyOrContactName(companies).map(company => {
          return company.contacts.length > 0 ? (
            <div
              style={{
                border: "3px solid #4db3bf",
                borderRadius: "2%",
                margin: "1%",
                padding: "1%",
                maxWidth: "90%",
                minWidth: "45%"
              }}
              key={company.id}
            >
              <h3 style={{ textTransform: "capitalize" }}>
                {company.name} ({company.contacts.length})
              </h3>

              {company.contacts.map(contact => (
                <div key={contact.id} style={{ padding: "4px 0" }}>
                  <Contact
                    deleteContact={deleteContact}
                    linkContactToListing={linkContactToListing}
                    unlinkContactToListing={unlinkContactToListing}
                    isListingContact={isListingContact}
                    contact={contact}
                    setShowForm={setShowForm}
                  />
                </div>
              ))}
            </div>
          ) : (
            <h3
              style={{
                margin: "1%",
                padding: "1%",
                textTransform: "capitalize",
                maxWidth: "90%",
                minWidth: "45%"
              }}
            >
              {company.name} ({company.contacts.length})
            </h3>
          );
        })}
      </div>
    </div>
  );
}
