export default (Base) => class extends Base {

  googleConnect() {
    if (!this.google) {
      if (this.googleScriptOnPage()) {
        this.googleScriptOnPage().addEventListener('load', this.loaded)
      } else {
        this.addScript()
      }
    } else {
      this.loaded()
    }
  }

  loaded = () => {
    if (this.googleScriptOnPage()) this.googleScriptOnPage().removeEventListener('load', this.loaded)
    if (this.googleConnected) this.googleConnected()
  }

  googleScriptOnPage() {
    for (let script of document.getElementsByTagName('script')) {
      if (script.src == 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCsfGsO4qHUKdJr-lpMuwF8CTd7XR-y-QQ&libraries=places') {
        return script
      }
    }
  }

  addScript() {
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.addEventListener('load', this.loaded)
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCsfGsO4qHUKdJr-lpMuwF8CTd7XR-y-QQ&libraries=places'
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  get google() {
    if (window.google) {
      return window.google.maps
    } else {
      return false
    }
  }
}
