import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['error']

  connect () {
    // $(this.element).on("ajax:send", this.send)
    // $(this.element).on("ajax:complete", this.complete)
  }

  disconnect () {
    // $(this.element).off("ajax:send", this.send)
    // $(this.element).off("ajax:complete", this.complete)
  }

  send = (event) => {
    // if (Turbolinks.controller.adapter.progressBar) {
    //   Turbolinks.controller.adapter.progressBar.setValue(0)
    //   Turbolinks.controller.adapter.progressBar.show()
    // }
    // document.activeElement.blur()
    // Turbolinks.dispatch('turbolinks:click', event)
  }

  complete =  (event, xhr) => {
    // if (Turbolinks.controller.adapter.progressBar) {
    //   Turbolinks.controller.adapter.progressBar.hide()
    //   Turbolinks.controller.adapter.progressBar.setValue(100)
    // }
    // if (!xhr.getResponseHeader('Location')) {
    //   this.errorTarget.innerHTML = xhr.responseText
    // }
  }

  rendered () {
    // Turbolinks.dispatch('turbolinks:load')
  }
}

