import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['reason', 'salePrice']

  changeReason() {
    if (this.reasonTarget.value == 'sold') {
      if (this.salePriceTarget.dataset.value == "") {
        this.salePriceTarget.value = this.salePriceTarget.dataset.value
      }
      this.salePriceTarget.disabled = false
    } else {
      this.salePriceTarget.value = ''
      this.salePriceTarget.disabled = true
    }
  }

  changeSalePrice() {
    this.salePriceTarget.dataset.value = this.salePriceTarget.value
  }
}
