import React, { useState } from 'react'

export default function CreateCompany({ createCompany, companyErrors, setShowCreateCompany }) {
  const [name, setName] = useState('')

  console.dir(companyErrors)

  return <div className="material">
    <h4 className="subtle-header">Create brokerage firm / company</h4>
      <div>
        <label>Name</label>

          <input
            type="text"
            className="form-control"
            style={{ margin: 10 }}

            name="company[name]"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
          {
            companyErrors.name.length > 0 
              ? <span className="validation-error-label">{companyErrors.name[0]}</span>
              : null
          }
    </div>
    <div>
      <input type="button" className="button" style={{ margin: 5 }} value="Save" onClick={() => {
        createCompany(name)
      }} />
      <input type="button" className="button" style={{ margin: 5 }} value="Cancel" onClick={() => {
        setShowCreateCompany(false)
      }} />
    </div>
  </div>
}