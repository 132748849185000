import React from "react";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import humanizeDuration from "humanize-duration";

function humanizeDays(val) {
  return humanizeDuration(Math.round(val / 86400) * 86400000, {
    units: ["d"]
  });
}
function ProductRow({
  match,
  category_id,
  is_set,
  is_set_price,
  product_id,
  product_name,
  product_purchase_price,
  sale_price_with_override,
  rental_price_with_override,
  roi_value,
  tag_number,
  time_inventoried,
  times_used,
  total_time_staged,
  product_retail_value
}) {
  const daysOfStaging = parseInt(humanizeDays(total_time_staged));
  const daysSinceStaged = parseInt(humanizeDays(time_inventoried));
  const percentUsed =
    daysOfStaging > 0 && daysSinceStaged > 0
      ? `${(parseFloat(daysOfStaging / daysSinceStaged) * 100).toFixed(1)}%`
      : "--";

  return (
    <tr>
      <td>
        <a
          href={`//${window.location.host}/${match.params.account_id}/products/${product_id}`}
        >
          {product_name}
        </a>
      </td>

      <td>{tag_number.replace(/^0+/, "")}</td>
      <td>
        <NumberFormat
          value={product_purchase_price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          fixedDecimalScale={true}
          decimalScale={2}
        />
        <sub>{is_set_price}</sub>
      </td>
      <td>
        <NumberFormat
          value={rental_price_with_override}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          fixedDecimalScale={true}
          decimalScale={2}
        />
        <sub>{is_set_price}</sub>
      </td>
      <td>
        <NumberFormat
          value={sale_price_with_override}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          fixedDecimalScale={true}
          decimalScale={2}
        />
        <sub>{is_set_price}</sub>
      </td>
      <td>{times_used}</td>
      <td>{daysSinceStaged}</td>
      <td>{daysOfStaging}</td>
      <td>{percentUsed}</td>
    </tr>
  );
}

export default withRouter(ProductRow);
