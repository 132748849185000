import React from 'react'
// Add sort options

export default function FilterCompanies({ companies, filteredCompany, setFilteredCompany, filteredContactName, setFilteredContactName }) {
  return <div className="row" style={{ margin: 10 }}>
    <h3>Filter by</h3>

    <div>
    <h4 className="data-label">Contact name</h4>
    <input type="text" onChange={(e) => {
      setFilteredContactName(e.target.value)
    }} value={filteredContactName ? filteredContactName : undefined} />
  </div>
    <div>
      <h4 className="data-label">Brokerage Firm / Company</h4>  
        <div className='col-md-12'> 
          <select
            className='form-control'
            onChange={(e) => {
              setFilteredCompany(companies.find(company => company.id === parseInt(e.target.value)))
            }}
            value={filteredCompany && filteredCompany.id ? filteredCompany.id : undefined}
          >
            <option>All brokerage firms / companies</option>
            {
              companies.map(company => <option
                  key={company.id}
                  value={company.id}
                >
                  { company.name }
                </option>)
            }
          </select>
        </div>
      </div>
  </div>

}