import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { BeatLoader } from "react-spinners";
import { withRouter } from "react-router-dom";

function WarehouseSummary({ warehouseId, match }) {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    fetch(
      `//${window.location.host}/${match.params.account_id}/warehouse_info/${warehouseId}`
    )
      .then(response => response.json())
      .then(json => {
        setData({
          name: json.warehouse_name,
          count: json.tags_count,
          sales: json.sale_total_warehouse,
          cost: json.working_cost_warehouse
        });
      });
  }, [warehouseId]);

  useEffect(() => {
    setLoaded(Object.keys(data).length > 0);
  }, [data]);

  return !loaded ? (
    <BeatLoader color={"#4db3bf"} />
  ) : (
    <div>
      <h1>Report for {data.name}</h1>
      <h2>Summary</h2>
      <table className="table">
        <tbody>
          <tr>
            <td># Tagged Items</td>
            <td>{data.count} </td>
          </tr>
          <tr>
            <td>Total Sale Value</td>
            <td>
              <NumberFormat
                value={data.sales}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                fixedDecimalScale={true}
                decimalScale={2}
              />
            </td>
          </tr>
          <tr>
            <td>Total Cost Value</td>
            <td>
              <NumberFormat
                value={data.cost}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                fixedDecimalScale={true}
                decimalScale={2}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default withRouter(WarehouseSummary);
