import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['progress', 'percent', 'disconnected', 'connected']

  connect () {
    this.clearAll()
    window.receiveLocate = this.receive
    window.readerPaired = this.readerPaired
    this.locateTag()
    this.readerPaired(false)
    if (window.webkit && window.webkit.messageHandlers.readerPaired) {
      window.webkit.messageHandlers.readerPaired.postMessage(null)
    }
    if (window.android && window.android.readerPaired) {
      window.android.readerPaired.postMessage(null)
    }

    if (window.webkit && !window.webkit.messageHandlers.appVersion) {
      alert('Please update your app. This version is not compatable with scanning')
    }
    if (window.android && !window.android.appVersion) {
      alert('Please update your app. This version is not compatable with scanning')
    }
  }

  disconnect () {
    window.receiveTags = null
  }

  locateTag() {
    if (window.webkit && window.webkit.messageHandlers.locateTag) {
      window.webkit.messageHandlers.locateTag.postMessage(this.element.dataset.tag)
    }
    if (window.android && window.android.locateTag) {
      window.android.locateTag(this.element.dataset.tag)
    }
  }

  clearAll() {
    if (window.webkit && window.webkit.messageHandlers.clearTags) {
      window.webkit.messageHandlers.clearTags.postMessage(null)
    }
    if (window.android && window.android.clearTags) {
      window.android.clearTags()
    }
  }

  openSettings() {
    if (window.webkit && window.webkit.messageHandlers.openSettings) {
      window.webkit.messageHandlers.openSettings.postMessage(null)
    }
    if (window.android && window.android.openSettings) {
      window.android.openSettings()
    }
  }

  receive = (tagRssi) => {
    this.progressTarget.style.width = `${tagRssi}%`
    this.percentTarget.innerHTML = `${tagRssi}%`
  }

  readerPaired = (paired) => {
    if (paired) {
      this.disconnectedTarget.style.display = 'none'
    } else {
      if (this.hasConnectedTarget) {
        this.connectedTarget.style.display = 'none'
      }
    }
  }
}

