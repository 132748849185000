import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    if (history.length == 1) {
      this.element.style.display = 'none'
    }
    if (!(window.webkit && window.webkit.messageHandlers.scanMode) && !(window.android && window.android.scanMode)) {
      this.element.style.display = 'none'
    }
  }

  go() {
    history.back()
  }

}
