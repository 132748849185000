import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this.position()
  }

  position() {
    this.element.style.left = window.scrollX + 'px';
  }
}

