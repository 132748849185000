export default function csrfToken() {
  var metas = document.getElementsByTagName('meta');
  var authenticityToken
  for (var i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === 'csrf-token') {
      authenticityToken = metas[i].getAttribute('content');
    }
  }

  return authenticityToken
}