import React, { Fragment } from 'react'
import ContactType from './contact-type'
import SelectCompany from './select-company'
import CreateCompany from './create-company'

export default function Form({
  contactTypes, selectedContactType, setSelectedContactType, setFormValues, formValues, saveContact, selectedCompany, setSelectedCompany, createCompany, setShowCreateCompany, showCreateCompany, companyErrors, companies, contact
}) {
  
  return <Fragment>
  <div className="contact_card">
    <div> 
      <ContactType
        contactTypes={contactTypes}
        setSelectedContactType={setSelectedContactType}
        selectedContactType={selectedContactType}
      />
      
        {
          selectedContactType
          ? showCreateCompany
            ? <CreateCompany
              createCompany={createCompany}
              setShowCreateCompany={setShowCreateCompany}
              companyErrors={companyErrors}
            />
            : <SelectCompany
              selectedCompany={selectedCompany}
              companies={companies}
              setShowCreateCompany={setShowCreateCompany}
              setSelectedCompany={setSelectedCompany}
            />
          : null
        }
      {
        selectedContactType && selectedContactType.show_name
          ? (
            <div className="col-sm-3"
            >
              <label className="data-label">Name: </label>
              <input 
                type="text"
                className="form-control"
                name="contact[name]"
                onChange={(e) => {
                  const value = e.target.value
                  setFormValues(prevState => ({
                    ...prevState,
                    name: value
                  }))
                }}
                value={formValues.name}
              />
            </div>
            
          )
          : null
      }
      {
        selectedContactType && selectedContactType.show_address
          ? (
            <div className="col-sm-3"
            >
              <label className="data-label">Address: </label>
              <textarea
                name="contact[address]"
                className="form-control"
                onChange={(e) => {
                  const value = e.target.value
                  setFormValues(prevState => ({
                    ...prevState,
                    address: value
                  }))
                }}
                value={formValues.address}
              ></textarea>
            </div>
            
          )
          : null
      }
      {
        selectedContactType && selectedContactType.show_phone
          ? (
            <div className="col-sm-3"
            >
              <label className="data-label">Phone: </label>
              <input
                type="text"
                className="form-control"
                name="contact[phone]"
                onChange={(e) => {
                  const value = e.target.value
                  setFormValues(prevState => ({
                    ...prevState,
                    phone: value
                  }))
                }}
                value={formValues.phone}
              />
            </div>
            
          )
          : null
      }
      {
        selectedContactType && selectedContactType.show_email
          ? (
            <div className="col-sm-3"
            >
              <label className="data-label">Email: </label>
              <input
                type="text"
                className="form-control"
                
                name="contact[email]"
                onChange={(e) => {
                  const value = e.target.value
                  setFormValues(prevState => ({
                    ...prevState,
                    email: value
                  }))
                }}
                value={formValues.email}
              />
            </div>        
          )       
          : null
      }

      </div>

      {
        selectedContactType
        ? (
          <div className="col-md-12">
            <input type="button"
              className="button"
              style={{ margin: 10, marginBottom: 20}}
              value="Save"
              onClick={saveContact}
              disabled={selectedCompany === null}
            />
          </div>
          )
        : null
      }
    </div>
  </Fragment>
}