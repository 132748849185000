import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link', 'disconnected', 'connected', 'form', 'loader', 'controls', 'limit', 'input']

  connect () {
    $("#unassigned").html("")
    $("#products").html("")
    $("#loader").show()
    this.clearAll()
    window.receiveTags = this.receive
    window.receiveBarcodes = this.receive
    window.readerPaired = this.readerPaired
    if (this.formTarget.scan.value == 'multi') {
      this.scanMultiple()
    }
    if (this.formTarget.scan.value == 'barcode') {
      this.barcodeMode()
    }

    this.href = this.linkTarget.href
    this.readerPaired(false)
    // if (window.webkit && window.webkit.messageHandlers.cameraMode) {
    //   this.inputTarget.style.display = 'none'
    // }
    if (window.webkit && window.webkit.messageHandlers.readerPaired) {
      window.webkit.messageHandlers.readerPaired.postMessage(null)
    }
    if (window.android && window.android.readerPaired) {
      window.android.readerPaired()
    }
    $(this.linkTarget).trigger('click')
    if (window.webkit && !window.webkit.messageHandlers.appVersion) {
      alert('Please update your app. This version is not compatable with scanning')
    }
    if (window.android && !window.android.appVersion) {
      alert('Please update your app. This version is not compatable with scanning')
    }
  }

  disconnect () {
    window.receiveTags = null
    this.linkTarget.href = this.href
  }

  remove(event) {
    $(event.currentTarget).parent().parent().remove()
  }

  cameraSingle() {
    if (window.webkit && window.webkit.messageHandlers.cameraMode) {
      window.webkit.messageHandlers.cameraMode.postMessage(null)
    }
    if (window.android && window.android.cameraMode) {
      window.android.cameraMode()
    }
  }

  barcodeMode() {
    if (window.webkit && window.webkit.messageHandlers.scanMode) {
      window.webkit.messageHandlers.scanMode.postMessage('barcode')
    }
    if (window.android && window.android.scanMode) {
      window.android.scanMode('barcode')
    }
  }

  inputMode() {
    window.receiveTags(prompt("Enter a Tag Number"))
  }

  scanMultiple() {
    this.formTarget.scan.value = 'multi'
    if (window.webkit && window.webkit.messageHandlers.scanMode) {
      window.webkit.messageHandlers.scanMode.postMessage('rfid')
    }
    if (window.android && window.android.scanMode) {
      window.android.scanMode('rfid')
    }
  }

  confirmClearAll(event) {
    if (confirm('Are you sure you want to remove ALL?')) {
      this.clearAll()
    } else {
      event.preventDefault()
    }
  }

  clearAll() {
    if (window.webkit && window.webkit.messageHandlers.clearTags) {
      window.webkit.messageHandlers.clearTags.postMessage(null)
    }
    if (window.android && window.android.clearTags) {
      window.android.clearTags()
    }
    $('.movable').remove()
    this.hideUnassigned()
  }

  openSettings() {
    if (window.webkit && window.webkit.messageHandlers.openSettings) {
      window.webkit.messageHandlers.openSettings.postMessage(null)
    }
    if (window.android && window.android.openSettings) {
      window.android.openSettings()
    }
  }

  hideUnassigned() {
    $('#unassigned').html('')
    $("#unassigned_title").hide()
  }

  test () {
    window.receiveTags('11737,8y76765654,11733,11730,117340')
  }

  readerPaired = (paired) => {
    if (paired) {
      this.disconnectedTarget.style.display = 'none'
    } else {
      if (this.hasConnectedTarget) {
        this.connectedTarget.style.display = 'none'
      }
    }
  }

  receive = (tags) => {
    $("#products").html("")
    $("#loader").show()

    this.linkTarget.href = `${ this.href }?tags=${ tags.split(',').slice(0, parseInt(this.limitTarget.value)).join(',') }`
    $(this.linkTarget).trigger('click')
  }
}

