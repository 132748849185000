import React, { useState, useEffect } from 'react'

import fetchApi from '../../modules/api-fetch'

import ContactType from './contact-type'
import SelectCompany from './select-company'
import CreateCompany from './create-company'
import FilterCompanies from './filter-companies'

import './styles.css'

export default function NewContactForm({ authenticity_token, account_id }) {
  const initialContactState = ({
    email: '',
    phone: '',
    address: '',
    name: ''
  })

  const [listingContacts, setListingContacts] = useState([])
  const [contactTypes, setContactTypes] = useState([])
  const [companies, setCompanies] = useState([])
  const [selectedContactType, setSelectedContactType] = useState('')
  const [filteredCompany, setFilteredCompany] = useState('')
  const [showCreateCompany, setShowCreateCompany] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [overContact, setOverContact] = useState(null)
  const [formValues, setFormValues] = useState(initialContactState)
  const [companyErrors, setCompanyErrors] = useState({
    name: []
  })
  const [contactErrors, setContactErrors] = useState({
    company_id: [],
    name: []
  })

  useEffect(() => {

    fetchApi(`${account_id}/contact_types.json`, 'GET')
      .then(json => setContactTypes(json))
    fetchApi(`${account_id}/companies.json`, 'GET')
      .then(json => setCompanies(json.companies.map(company => ({
        ...company,
        contacts: sortByName(company.contacts)
      }))))

  }, [])

  function sortByName(array) {
    return array.sort((a, b) => {
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    })
  }

  function deleteContact(contact) {
    fetchApi(`${account_id}/contacts/${contact.id}`, 'DELETE', {
      authenticity_token,
      company_id: contact.company_id
    })
    .then(json => {
      setCompanies(prevState => ({
        companies: prevState.map(company => ({
          ...company,
          contacts: company.contacts.filter(_contact => _contact.id !== contact.id)
        })),
      }))

      setFormValues(initialContactState)
      setSelectedContactType(null)
      setSelectedCompany(null)
    })
  }

  function createContact() {
    fetchApi(`${account_id}/contacts`, 'POST', {
      authenticity_token,
      contact: {
        ...formValues,
        contact_type_id: selectedContactType.id
      },
      company_id: selectedCompany.id
    })
    .then(json => {
      if(json.errors) {
        setContactErrors(json.errors)
        return 
      }

      setCompanies(prevState => (prevState.map(company => ({
        ...company,
        contacts: json.contact.company_id === company.id
          ? [...company.contacts, json.contact]
          : company.contacts
      }))))


      setFormValues(initialContactState)
      setSelectedContactType(prev => ({ ...prev }))
      setSelectedCompany(prev => ({ ...prev }))
    })
  }

  function createCompany(name) {
    fetchApi(`${account_id}/companies`, 'POST', {
      authenticty_token,
      company: {
        name: name
      }
    })
    .then(json => {
      if(json.errors) {
        setCompanyErrors(json.errors)
        console.log(json.errors)
        return 
      }

      setCompanies(prevState => ([...prevState, json.company]))
      setSelectedCompany(json.company)
      setShowCreateCompany(false)
    })
  }

  function deleteCompany(company) {
    fetchApi(`${account_id}/companies/${company.id}`, 'DELETE', {
      authenticty_token,
      company_id: company.id
    })
    .then(json => {
      setCompanies(prevState => prevState.filter(_company => company.id !== _company.id))

      setSelectedCompany(null)
    })
  }

  // function isListingContact(contact_id) {
  //   return listingContacts.find(lc => contact_id === lc.contact_id)
  // }

  // function linkContactToListing(contact) {
  //   fetchApi(`${account_id}/listings/${listing_id}/contacts`, 'POST', {
  //     authenticty_token,
  //     contact_id: contact.id
  //   }).then(json => {
  //     setListingContacts(prevState => ([
  //       ...prevState,
  //       json
  //     ]))
  //   })
  // }

  // function unlinkContactToListing(contact) {
  //   fetchApi(`${account_id}/listings/${listing_id}/contacts`, 'DELETE', {
  //     authenticty_token,
  //     contact_id: contact.id
  //   }).then(json => {
  //     setListingContacts(prevState => prevState.filter(lc => lc.contact_id !== contact.id))
  //   })
  // }


  return <div>
    <h4 className="subtle-header">Select clients(s)</h4>
    <FilterCompanies
      companies={companies}
      filteredCompany={filteredCompany}
      setFilteredCompany={setFilteredCompany}
    />

    { filteredCompany && filteredCompany.contacts.map(contact =>  <div
        key={contact.id}
          onMouseOver={() => setOverContact(contact)}
          className="card"

          style={{ padding: '4px 0', width: '100%' }}
        >
        <div className="row">
          <div className="col-md-6">
            <p>
            { contact.name } - { contact.contact_type_label}
            </p>
        
            <p>
            {contact.phone}
            </p> &nbsp;
            {
              overContact && overContact.id=== contact.id
              ? (
                <a  onClick={() => deleteContact(contact)}>Delete</a>
              )
              : null
            }
          </div>
        </div>


      
      
        </div>)
      }


  <div className="material"
  style={{ margin: '2%', padding: '2%' }}
  >
    <div className="row">
      <h4 className="subtle-header">Create new client</h4>
      
      <ContactType
        contactTypes={contactTypes}
        setSelectedContactType={setSelectedContactType}
        selectedContactType={selectedContactType}
      />
      {
        selectedContactType
        ? showCreateCompany
          ? <CreateCompany
            createCompany={createCompany}
            setShowCreateCompany={setShowCreateCompany}
            companyErrors={companyErrors}
          />
          : <SelectCompany
            selectedCompany={selectedCompany}
            companies={companies}
            setShowCreateCompany={setShowCreateCompany}
            setSelectedCompany={setSelectedCompany}
          />
        : null
      }
      <div style={{margin: 5}}> 

      {
        selectedContactType && selectedContactType.show_name
          ? (
            <div className="col-sm-3"
            >
              <label className="data-label">Name: </label>
              <input 
                type="text"
                className="form-control"
                name="contact[name]"
                onChange={(e) => {
                  const value = e.target.value
                  setFormValues(prevState => ({
                    ...prevState,
                    name: value
                  }))
                }}
                value={formValues.name}
              />
            </div>
            
          )
          : null
      }
      {
        selectedContactType && selectedContactType.show_address
          ? (
            <div className="col-sm-3"
            >
              <label className="data-label">Address: </label>
              <textarea
                name="contact[address]"
                className="form-control"
                onChange={(e) => {
                  const value = e.target.value
                  setFormValues(prevState => ({
                    ...prevState,
                    address: value
                  }))
                }}
                value={formValues.address}
              ></textarea>
            </div>
            
          )
          : null
      }
      {
        selectedContactType && selectedContactType.show_phone
          ? (
            <div className="col-sm-3"
            >
              <label className="data-label">Phone: </label>
              <input
                type="text"
                className="form-control"
                name="contact[phone]"
                onChange={(e) => {
                  const value = e.target.value
                  setFormValues(prevState => ({
                    ...prevState,
                    phone: value
                  }))
                }}
                value={formValues.phone}
              />
            </div>
            
          )
          : null
      }
      {
        selectedContactType && selectedContactType.show_email
          ? (
            <div className="col-sm-3"
            >
              <label className="data-label">Email: </label>
              <input
                type="text"
                className="form-control"
                
                name="contact[email]"
                onChange={(e) => {
                  const value = e.target.value
                  setFormValues(prevState => ({
                    ...prevState,
                    email: value
                  }))
                }}
                value={formValues.email}
              />
            </div>        
          )       
          : null
      }

      </div>

      {
        selectedContactType
        ? (
          <div className="col-md-12">
          <input type="button"
            className="button"
            style={{ margin: 10 }}
            value="Save"
            onClick={createContact}
            disabled={selectedCompany === null}
          />
          </div>
          )
        : null
      }
    
      </div>
    </div>
  </div>
} 
