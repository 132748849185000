import React, {useState, Fragment} from 'react'
import Form from './form'
//import moment from 'moment'
import moment from 'moment-timezone'

import getCsrfToken from '../../modules/csrf-token'

export default function Event(props){

  const initialEventState = ({
    id: props.id,
    name: props.name || '', 
    address: props.address || '',
    occursAt: props.occursAt || '',
    description: props.description || '',
    location: props.location || '',
    durationInMinutes: props.durationInMinutes || 30,
    allDay: props.allDay || false,
    eventTypeId: props.eventTypeId,
    eventType: props.eventType,
    errorMsg: []
  })

  const [showForm, setShowForm] = useState(false)
  const [state, setState] = useState(initialEventState)
  const [show, setShow] = useState(false)
  const [showDescription, setShowDescription] = useState(false)



  function afterSave(json) {
    setState({...json})
    setShowForm(false)
  }


  return (
    <div className="card col-md-12 material">
    {
      showForm
        ? <Form {...state} authenticityToken={getCsrfToken()} accountHandle={props.accountHandle} listingId={props.listingId} id={props.id} {...state} close={() => setShowForm(false)} success={afterSave}/>
        : <EventDetails
          listingId={props.listingId}
          accountHandle={props.accountHandle}
          {...state}
          setShowForm={setShowForm}
          showForm={showForm}
          deleteEvent={props.deleteEvent}
          setShowDescription={setShowDescription}
          showDescription={showDescription}
          location={state.location}
        />
    } 
      
    </div>
  )
}


function EventDetails({
  name, occursAt, description, id, accountHandle, listingId, setShow, setShowForm, deleteEvent, setShowDescription, showDescription, location, allDay, eventType
}) {


  function download(url) {
    var filename = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
    return new Promise(function(resolve, reject) {
      // Get file name from url.
      
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function() {
        resolve(xhr);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.send();
    }).then(function(xhr) {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
      a.download = filename; // Set the file name.
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      return xhr;
    });
  }
  

  function downloadIcs(accountHandle, listingId, eventId) {
    const timeZone = moment.tz.guess();

    const url = `/${accountHandle}/listings/${listingId}/listing_events/${eventId}.ics?time_zone=${timeZone}`
    download(url)
  }
  
  return(
    <Fragment>
      <div className="flex-card">
        <div>
          <div>
            <div className="title subtle-header">
              <p>{name} </p><p>{moment(occursAt).format('dddd, MMMM Do YYYY hh:mm a')}</p>
              <p>Event Type: {eventType && eventType.name} 
              </p>

            </div>
            

            <div className="appt">
            </div>
          </div>
      

          <div> 
            {
              description && description.length > 0 
                ?  <div>
                   <div className="col-sm-12 title subtle-header">Event Details:
                   </div> {description}
              </div>
             : null
            }
            
        
        </div>
      </div>
      
    
        <div className="footer">
          <button type="button" className="icon-btn" onClick={() => {
            downloadIcs(accountHandle, listingId, id)
          }}><i className="fa fa-download"></i></button> 
          <button type="button" className="icon-btn"  onClick={() => setShowForm(true)}><i className="fa fa-edit"></i></button>
          <button type="button" className="icon-btn" onClick={() => deleteEvent(id)}><i className="fa fa-trash"></i></button>
        </div>

      </div>
 
    </Fragment>
  )
}