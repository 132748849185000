import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {

  connect () {
    if ((!/android/i.test(navigator.userAgent) && !/iPad|iPhone|iPod/.test(navigator.userAgent)) && !(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) || this.element.dataset.url) {
      if (this.element.dataset.url) {
        $(this.element).select2({ ajax: { url: this.element.dataset.url, dataType: 'json', processResults: this.processResults }, width: '100%', allowClear: true, placeholder: '' })
      } else {
        $(this.element).select2({tags: true, width: '100%', allowClear: true, placeholder: ''})
      }
    } else {
      this.element.className = 'w-100 flex justify-center items-center pv2 ph3 no-outline input-reset f6 fw6 bg-white br1 ba b--black-20 dowpdown-arrow'
    }
  }

  disconnect () {
    if (window.width > 480) {
      $(this.element).select2('destroy')
    }
  }


  processResults = (data, params) => {
    params.page = params.page || 1
    return {
      results: data.items,
      pagination: {
        more: (params.page * this.element.dataset.perPage) < data.total
      }
    }
  }
}

