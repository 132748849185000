import { Controller } from 'stimulus'
import Google from 'mixins/google'

export default class extends Google(Controller) {

  connect() {
    $(".bootstrap-multiselect").multiselect('destroy');
    $(".bootstrap-multiselect").parent().find('.btn-group').remove()
    $(".bootstrap-multiselect").multiselect({
      preventInputChangeEvent: true,
    });
  }

  disconnect() {
    $(".bootstrap-multiselect").multiselect('destroy');
    $(".bootstrap-multiselect").parent().find('.btn-group').remove()
  }

}

