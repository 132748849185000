import { Controller } from 'stimulus'
import tippy from 'tippy.js';

export default class extends Controller {

  connect() {
    tippy(this.element, {
      // theme: 'light',
      allowHTML: true,
    });
  }

}
