import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  connect () {
    $(this.inputTarget).typeahead({ hint: false, highlight: true, minLength: 0 },
    {
      name: 'last-location',
      display: 'name',
      source: this._source
    })
  }

  disconnect () {
  }

  get _source () {
    return new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      limit: 20,
      remote: {
        url: `${this.element.dataset.source}?q=%QUERY`,
        wildcard: '%QUERY'
      }
    })
  }

}

