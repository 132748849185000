import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['overlay', 'button']

  connect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.add(...this.buttonTarget.dataset.inactiveClass.split(' '))
    }
  }

  toggle(event) {
    event.preventDefault()
    if (this.visible()) {
      this.hideOverlay()
    } else {
      this.showOverlay()
    }
  }

  visible() {
    return this.overlayTarget.classList.contains(this.overlayTarget.dataset.animateIn)
  }

  showOverlay() {
    for(let controller of this.application.controllers) {
      if (typeof controller.hideOverlay === 'function') {
        controller.hideOverlay()
      }
    }
    this.overlayTarget.style.display = ''
    this.overlayTarget.classList.remove(this.overlayTarget.dataset.animateOut)
    this.overlayTarget.classList.add(this.overlayTarget.dataset.animateIn)
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.remove(...this.buttonTarget.dataset.inactiveClass.split(' '))
      this.buttonTarget.classList.add(...this.buttonTarget.dataset.activeClass.split(' '))
    }
  }

  clear() {
    if (!this.visible()) this.overlayTarget.style.display = 'none'
  }

  hideOverlay() {
    if (this.hasOverlayTarget) {
      this.overlayTarget.classList.remove(this.overlayTarget.dataset.animateIn)
      this.overlayTarget.classList.add(this.overlayTarget.dataset.animateOut)
    }
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.remove(...this.buttonTarget.dataset.activeClass.split(' '))
      this.buttonTarget.classList.add(...this.buttonTarget.dataset.inactiveClass.split(' '))
    }
  }

  closeIfClickedOutside = (event) => {
    if (!this._isDescendant(this.element, event.target)) {
      this.hideOverlay()
    }
  }

  _isDescendant(parent, child) {
    let node = child.parentNode
    while (node != null) {
      if (node == parent) {
        return true
      }
      node = node.parentNode
     }
     return false
   }
}
