import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link', 'submit', 'cover', 'progress', 'complete', 'title']

  connect() {
    this.href = this.linkTarget.href
    $(this.submitTarget).on("ajax:send", this.send)
    $(this.submitTarget).on("ajax:complete", this.submitComplete)
    $(this.linkTarget).on("ajax:complete", this.checkComplete)
  }

  disconnect() {
    this.linkTarget.href = this.href
    $(this.submitTarget).off("ajax:send", this.send)
    $(this.submitTarget).off("ajax:complete", this.submitComplete)
    $(this.linkTarget).off("ajax:complete", this.checkComplete)
  }

  send = (event) => {
    console.log('send')
    if (event.target != this.submitTarget) return
    this.coverTarget.style.display = 'flex'
  }

  submitComplete = (event, xhr) => {
    if (event.target != this.submitTarget) return
    try {
      let response = JSON.parse(xhr.responseText)
      if (response.error) {
        alert(response.error)
        this.coverTarget.style.display = 'none'
        this.completeTarget.click()
        return
      }
    } catch (e) {
      console.log('error', xhr.responseText)
      this.coverTarget.style.display = 'none'
      this.completeTarget.click()
      try {
        eval(xhr.responseText)
      } catch (e) { }
      return
    }
    this.jobIds = xhr.responseText
    if (this.jobIds.length == 0) {
      this.coverTarget.style.display = 'none'
      this.completeTarget.click()
    } else {
      this.check()
    }
  }

  checkComplete = (event, xhr) => {
    if (event.target != this.linkTarget) return
    event.stopPropagation()
    let jobs = JSON.parse(xhr.responseText)
    this.progressTarget.style.width = `${jobs.filter(j => j.status == 'complete').length / this.jobIds.length * 100}%`
    if (jobs.filter(j => j.status == 'complete').length == this.jobIds.length) {
      this.coverTarget.style.display = 'none'
      this.completeTarget.click()
    } else {
      if ((jobs.filter(j => j.status == 'complete').length + jobs.filter(j => j.status == 'failed').length) == this.jobIds.length) {
        this.titleTarget.innerHTML = `${jobs.filter(j => j.status == 'failed').length} Failures`
        this.completeTarget.style.display = ''
      } else {
        setTimeout(this.check, this.sleep)
      }
    }
  }

  check = () => {
    this.linkTarget.href = `${this.href}?ids=${this.jobIds}`
    $(this.linkTarget).trigger('click')
  }

  get sleep() {
    return parseInt(this.data.get('sleep')) || 2000
  }

  get jobIds() {
    return JSON.parse(this.data.get('jobIds'))
  }

  set jobIds(value) {
    this.data.set('jobIds', value)
  }
}

