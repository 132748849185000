import React, { useState, useEffect } from "react";
import apiFetch, { abortController } from "../../modules/abortable-api-fetch";

const style = {
  fontWeight: 400,
  fontSize: "14px",
  display: "block",
  margin: "0",
  color: "#404040",
};
export default function WarehouseSectionDropdown({
  authenticityToken,
  model,
  updateUrl,
  section,
  sections,
}) {
  const [selectedSectionId, setSelectedSectionId] = useState(
    section && section.id
  );

  function Option({ value, children }) {
    return (
      <option value={value} style={style}>
        {children}
      </option>
    );
  }

  return (
    <>
      <label style={style}>Default section:</label>
      <select
        value={selectedSectionId}
        onChange={(e) => {
          const warehouseSectionId = e.target.value;
          setSelectedSectionId(warehouseSectionId);
          apiFetch(abortController.signal, updateUrl, "put", {
            authenticity_token: authenticityToken,
            [model]: {
              warehouse_section_id:
                warehouseSectionId > 0 ? warehouseSectionId : null,
            },
          });
        }}
        style={style}
      >
        <Option value={0}>None selected</Option>
        {sections.map((section) => (
          <Option value={section.id} key={section.key}>
            {section.name}
          </Option>
        ))}
      </select>
    </>
  );
}
