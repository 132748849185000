import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'checkbox', 'dialog', 'agree' ]

  confirm(event) {
    if (this.checkboxTarget.checked) {
      event.preventDefault()
      event.stopImmediatePropagation()
      this.show()
    }
  }

  validate() {
    if (this.agreeTarget.checked) {
      this.checkboxTarget.checked = true
      $(this.checkboxTarget.form).submit()
      this.hide()
    } else {
      alert('Please agree to the terms and conditions')
    }
  }

  show() {
    this.dialogTarget.style.display = ''
  }

  hide() {
    this.dialogTarget.style.display = 'none'
    this.agreeTarget.checked = false
  }
}

