import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import AbortController from "abort-controller";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import apiFetch from "../../../modules/abortable-api-fetch";
import Filters from "./filters";

function Manifest({ match, history }) {
  const abortController = new AbortController();
  const [selectedSort, setSelectedSort] = useState({
    attr: "product",
    dir: "asc",
  });

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(null);

  const [selectedWarehouseId, setSelectedWarehouseId] = useState(
    match.params.id
  );
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState([]);
  const [rendered, setRendered] = useState([]);

  const [categories, setCategories] = useState([]);

  const [filtered, setFiltered] = useState([]);
  const [rfidTagFilter, setRfidTagFilter] = useState("");
  const [productFilter, setProductFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState([]);

  useScrollPosition(({ prevPos, currPos }) => {
    if (
      document.getElementById("maninfestReportTable").clientHeight <
      -currPos.y + 1000
    )
      renderMore();
  });

  function renderMore() {
    setRendered((prev) => {
      return [...prev, ...filtered.slice(prev.length, prev.length + 100)];
    });
  }

  function changeSort(attr) {
    const dir =
      selectedSort.attr === attr
        ? selectedSort.dir === "asc"
          ? "desc"
          : "asc"
        : "asc";
    const sort = {
      attr,
      dir,
    };
    setSelectedSort(sort);
  }

  useEffect(() => {
    loadCategories();
    loadWarehouses();
    loadWarehouse();
  }, []);

  useEffect(() => {
    loadWarehouse();
  }, [selectedSort, selectedWarehouseId]);

  useEffect(() => {
    setFiltered(loaded.filter((row) => matchesFilter(row)));
  }, [productFilter, rfidTagFilter, categoryFilter, loaded]);

  useEffect(() => {
    console.dir("filtered changed, rerender " + filtered.length);
    setRendered(filtered.slice(0, 100));
  }, [filtered]);

  function loadWarehouses() {
    const url = `${match.params.account_id}/json/warehouses.json`;
    apiFetch(abortController.signal, url).then((json) => {
      setWarehouses(json.warehouses);
    });
  }

  function loadCategories() {
    const url = `${match.params.account_id}/json/categories.json`;
    apiFetch(abortController.signal, url).then((json) => {
      setCategories(json.categories);
    });
  }

  function loadWarehouse() {
    if (selectedWarehouseId < 1) return;
    const url = `${match.params.account_id}/json/warehouses/${selectedWarehouseId}.json`;
    apiFetch(abortController.signal, url).then((json) => {
      setWarehouse(json.warehouse);
    });

    setLoading(true);
    apiFetch(
      abortController.signal,
      `${match.params.account_id}/json/warehouses/${selectedWarehouseId}/rfid_tags.json?sort=${selectedSort.attr}&dir=${selectedSort.dir}`
    ).then((json) => {
      setLoaded(json);
      setLoading(false);
    });
  }

  function matchesFilter(row) {
    if (
      rfidTagFilter.length === 0 &&
      productFilter.length === 0 &&
      categoryFilter.length === 0
    )
      return true;
    let found = false;

    // category && others (inclusive)
    if (
      categoryFilter.length > 0 &&
      categoryFilter.filter((cat) => cat.label === row.primary_category)
        .length > 0
    )
      found.push("primary_category");

    // if rfid tag matches, true, else false (exclusive)
    if (rfidTagFilter.length > 0) {
      return row.rfid_tag.toString() === rfidTagFilter;
    }

    // if product name matches, true, else false (exclusive)
    if (productFilter.length > 0) {
      return row.product_name
        .toString()
        .toLowerCase()
        .includes(productFilter.toLowerCase());
    }

    return found.length > 0;
  }

  function renderManifest() {
    if (!warehouse) return;
    return (
      <div>
        <div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ flex: "80%" }}>
              <h1>Warehouse Manifest for {warehouse.name}</h1>
            </div>
            <div style={{ flex: "1", textAlign: "right", marginTop: "2rem" }}>
              <button
                onClick={() =>
                  (window.location.href = `${window.location.href}.csv?sort=${selectedSort.attr}&dir=${selectedSort.dir}`)
                }
                className="button btn-primary"
              >
                Export CSV
              </button>
            </div>
          </div>

          {/*
          <Filters
            rfidTagFilter={rfidTagFilter}
            setRfidTagFilter={setRfidTagFilter}
            productFilter={productFilter}
            setProductFilter={setProductFilter}
            categoryFilter={categoryFilter}
            setCategoryFilter={setCategoryFilter}
            categories={categories}
          />
          */}

          <table className="table table-striped" id="maninfestReportTable">
            <thead>
              <tr>
                <th width="10%">#</th>
                <th width="10%">
                  <a onClick={() => changeSort("rfid")}>RFID Tag #</a>
                </th>
                <th width="20%">
                  <a onClick={() => changeSort("product")}>Product</a>
                </th>
                <th width="20%">Part</th>
                <th width="15%">
                  <a onClick={() => changeSort("category")}>Category</a>
                </th>
                <th width="10%">Subcategory</th>
                <th width="10%">Section</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={3}>Loading....</td>
                </tr>
              ) : (
                rendered.map((rfidTag, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>

                    {rfidTag.rfid_tag != "" ? (
                      <td>
                        <a
                          target="_blank"
                          href={`/${match.params.account_id}/rfid_tags/${rfidTag.rfid_tag_id}`}
                        >
                          {rfidTag.rfid_tag}
                        </a>
                      </td>
                    ) : (
                      <td>missing part</td>
                    )}
                    <td>
                      <a
                        target="_blank"
                        href={`/${match.params.account_id}/products/${rfidTag.product_id}`}
                      >
                        {rfidTag.product_name}
                      </a>
                    </td>
                    <td>{rfidTag.part_name}</td>
                    <td>{rfidTag.primary_category}</td>
                    <td>{rfidTag.subcategory}</td>
                    <td>{rfidTag.warehouse_section_name}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div>
      <select
        className="form-control"
        onChange={(e) => {
          setSelectedWarehouseId(e.target.value);
          history.push(
            `/${match.params.account_id}/reports/warehouses/${e.target.value}/manifest`
          );
        }}
        value={selectedWarehouseId}
      >
        <option value={0}>Select warehouse</option>
        {warehouses.map((warehouse) => (
          <option value={warehouse.id}>{warehouse.name}</option>
        ))}
      </select>
      {selectedWarehouseId < 1 ? (
        <div>
          <div
            className="info-box"
            style={{
              color: "#afafaf",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "12px",
              marginTop: "5px",
            }}
          >
            <i className="fa fa-info-circle" aria-hidden="true"></i> Please
            choose a warehouse.
          </div>
        </div>
      ) : (
        renderManifest()
      )}
    </div>
  );
}

export default withRouter(Manifest);
