import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import AbortController from "abort-controller";
import queryString from "query-string";

import "./styles.css";

import ProductSearchFilters from "./filters";
import Totals from "./totals";
import Results from "./results";
import apiFetch from "../../../modules/abortable-api-fetch";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

function ProductsReport({ match, history }) {
  const params = queryString.parse(window.location.search);

  const [attrSearch, setAttrSearch] = useState(params.query || "");
  const [numberSearch, setNumberSearch] = useState(params.number || "");
  const [selectedListings, setSelectedListings] = useState(
    (params.listed_at && params.listed_at.split(",")) || []
  );
  const [selectedWarehoused, setSelectedWarehoused] = useState(
    (params.warehoused_at && params.warehoused_at.split(",")) || []
  );
  const [sortBy, setSortBy] = useState(params.sort_by || "");

  const [selectedCategories, setSelectedCategories] = useState(
    (params.categories && params.categories.split(",")) || []
  );
  const [selectedSubcategories, setSelectedSubcategories] = useState(
    (params.subcategories && params.subcategories.split(",")) || []
  );
  const [subcategories, setSubcategories] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState([]);
  const [rendered, setRendered] = useState([]);
  const [totals, setTotals] = useState({});

  const abortController = new AbortController();

  useScrollPosition(({ prevPos, currPos }) => {
    if (
      document.getElementById("productsReportTable").clientHeight <
      -currPos.y + 1000
    )
      renderMore();
  });

  useEffect(() => {
    loadData(abortController);
    return () => {
      abortController.abort();
    };
  }, [
    numberSearch,
    attrSearch,
    selectedListings,
    selectedWarehoused,
    sortBy,
    selectedCategories,
    selectedSubcategories
  ]);

  useEffect(() => {
    apiFetch(
      abortController.signal,
      `${match.params.account_id
      }/subcategories.json?category_ids=${selectedCategories.join(",")}`
    ).then(json => {
      setSubcategories(
        json.subcategories.map(sc => ({
          id: sc.id,
          label: sc.name
        }))
      );
    });
  }, [selectedCategories]);

  function renderMore() {
    setRendered(prev => {
      return [...prev, ...loaded.slice(prev.length, prev.length + 100)];
    });
  }

  function loadData(abortController) {
    const params = getSearchParams();
    if (params.length > 0) {
      history.push({
        search: `?${params}`
      });
    }
    setLoading(true);
    apiFetch(
      abortController.signal,
      `${match.params.account_id}/reports/products.json?${params}`
    ).then(json => {
      console.log(json);
      json.totals[0].purchase_price_sum = json.data.map((d) => parseFloat(d.product_purchase_price)).reduce((a, b) => a + b, 0);
      json.totals[0].sale_price_sum = json.data.map((d) => parseFloat(d.product_sale_price)).reduce((a, b) => a + b, 0);
      json.totals[0].rental_price_sum = json.data.map((d) => parseFloat(d.product_rental_price)).reduce((a, b) => a + b, 0);
      setLoaded(prev => json.data);
      setTotals(...json.totals);
      setLoading(false);
      setRendered(prev => json.data.slice(0, 100));
    });
  }

  function resetFilters() {
    setAttrSearch("");
    setNumberSearch("");
    setSelectedListings([]);
    setSelectedWarehoused([]);
    setSortBy('');
    setSelectedCategories([]);
    setSelectedSubcategories([]);
    setSubcategories([]);
  }

  function getSearchParams() {
    const params = [];

    if (numberSearch.length > 0) params.push(`number=${numberSearch}`);
    if (sortBy.length > 0) params.push(`sort_by=${sortBy}`);
    if (attrSearch.length > 3) params.push(`query=${attrSearch}`);
    if (selectedListings.length > 0)
      params.push(`listed_at=${selectedListings.join(",")}`);
    if (selectedWarehoused.length > 0)
      params.push(`warehoused_at=${selectedWarehoused.join(",")}`);
    if (selectedCategories.length > 0)
      params.push(`categories=${selectedCategories.join(",")}`);
    if (selectedSubcategories.length > 0)
      params.push(`subcategories=${selectedSubcategories.join(",")}`);

    return params.join("&");
  }
  return (
    <div>
      <h1>Products Report</h1>
      <ProductSearchFilters
        selectedListings={selectedListings}
        setSelectedListings={setSelectedListings}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        selectedSubcategories={selectedSubcategories}
        setSelectedSubcategories={setSelectedSubcategories}
        subcategories={subcategories}
        selectedWarehoused={selectedWarehoused}
        setSelectedWarehoused={setSelectedWarehoused}
        numberSearch={numberSearch}
        setNumberSearch={setNumberSearch}
        attrSearch={attrSearch}
        setAttrSearch={setAttrSearch}
        searchParams={getSearchParams()}
        resetFilters={resetFilters}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />

      <Totals totals={totals} loading={loading} />

      <div>
        <Results totals={totals} data={rendered} loading={loading} />
      </div>
    </div>
  );
}

export default withRouter(ProductsReport);
