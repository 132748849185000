import React from 'react'
import Event from './event'

export default function EventCalendarList({ events, accountHandle, listingId, deleteEvent }) {
  return <div className="flex-container">
  {
    events.map(event => {

      return <Event 
        key={event.id}
        {...event}
        listingId={listingId}
        accountHandle={accountHandle}
        deleteEvent={deleteEvent}
      />
    })
  }
  </div>
}