import { Controller } from 'stimulus'

export default class extends Controller {

  connect () {
    try {
      if (!(window.webkit && window.webkit.messageHandlers.scanMode) && !(window.android && window.android.scanMode)) {
        this.element.style.display = 'none'
      }
    } catch (e) {
      this.element.style.display = 'none'
    }
  }
}
