import React from "react";
import onClickOutside from "react-onclickoutside";

// MUST BE AN ES6 CLASS!!! (includes a singleton for blur)
class CheckboxDropdown extends React.Component {
  state = {
    open: false,
    filter: ""
  };

  handleClickOutside = evt => {
    this.setState({ open: false });
  };

  toggleSelectedId = id => {
    if (!this.props.multiple) {
      this.selectId(id);
    } else {
      if (this.props.selectedIds.includes(id)) {
        this.props.setSelectedIds(prevState =>
          prevState.filter(_id => id !== _id)
        );
      } else {
        this.props.setSelectedIds(prevState => [...prevState, id]);
      }
    }
  };

  selectId = id => {
    this.props.setSelectedIds([id]);
  };

  formattedValue = () => {
    const { options, multiple, noneSelectedText, selectedIds } = this.props;
    const firstSelectedOption = options.find(
      option => option.id === parseInt(selectedIds[0])
    );
    return multiple
      ? selectedIds.length < 1
        ? noneSelectedText
        : selectedIds.length > 1
        ? `${firstSelectedOption &&
            firstSelectedOption.label} + ${selectedIds.length - 1} more`
        : firstSelectedOption && firstSelectedOption.label
      : firstSelectedOption && firstSelectedOption.label;
  };

  filter(options) {
    return options.filter(
      option =>
        option.label.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1
    );
  }

  render() {
    const {
      multiple,
      label,
      noneSelectedText,
      selectedIds,
      options
    } = this.props;
    const { open } = this.state;

    return (
      <div className="filter-styled inline-block">
        <div className="filter-label">{label}</div>
        <div className={`btn-group ${open ? "open" : ""}`}>
          <button
            type="button"
            className="multiselect dropdown-toggle"
            style={{
              border: "1px solid #cccccc",
              borderRadius: "4px",
              padding: "6px"
            }}
            title={noneSelectedText}
            aria-expanded="true"
            onClick={e => {
              this.setState(prevState => ({ open: !prevState.open }));
            }}
          >
            {this.formattedValue()}
            <b className="caret"></b>
          </button>
          <ul className="multiselect-container dropdown-menu">
            <li>
              <input
                type="text"
                className="input full-width"
                placeholder="Type to filter results"
                onChange={e => this.setState({ filter: e.target.value })}
                value={this.state.filter}
              />
            </li>
            {this.filter(options).map(option => {
              const selected = selectedIds.includes(option.id.toString());
              return (
                <li key={option.id} className={selected ? "active" : ""}>
                  <a href="javascript:void(0);">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        value={option.id}
                        checked={selected}
                        onChange={e =>
                          this.toggleSelectedId(e.target.value.toString())
                        }
                      />{" "}
                      {option.label}
                    </label>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default onClickOutside(CheckboxDropdown);
