import React, { useState, useEffect } from "react";
import moment from "moment";
import * as Datetime from "react-datetime";
import List from "./list";
import Form from "./form";

import "./styles.scss";
import "react-datetime/css/react-datetime.css";

import fetchApi from "../../modules/api-fetch";

export default function EventCalendar({
  listingId,
  events,
  short,
  title,
  authenticityToken,
  accountHandle
}) {
  const [show, setShow] = useState(false);
  const [items, setItems] = useState(events.map(event => event.listingEvent));
  const [showForm, setShowForm] = useState(false);
  const [sortBy, setSortBy] = useState("created_at");

  useEffect(() => {
    setItems(
      items.sort((a, b) => {
        return new Date(a[sortBy]).getTime() - new Date(b[sortBy]).getTime();
      })
    );
  }, [sortBy]);

  function afterCreate(json) {
    setItems(prev => [...prev, json]);
    setShowForm(false);
  }

  function deleteEvent(id) {
    // confirm
    fetchApi(
      `${accountHandle}/listings/${listingId}/listing_events/${id}`,
      "delete",
      {
        authenticity_token: authenticityToken
      }
    ).then(json => {
      setItems(prev => [...prev.filter(ev => ev.id !== id)]);
    });
  }

  return (
    <div className={`col-md-12 material expander ${short} not-loaded`}>
      <div className="header" onClick={() => setShow(prev => !prev)}>
        <h3 className="subtle-header name">
          <i className="fa fa-sticky-note"></i> <span id="count">{title}</span>
        </h3>
        <a className="expand">
          <i className="fa fa-expand"></i>
        </a>
      </div>

      <div
        style={{
          display: show ? "block" : "none",
          marginTop: "30px"
        }}
      >
        {showForm ? (
          <div
            style={{ boxSizing: "border-box", margin: "8px", padding: "8px" }}
            className="col-sm-12"
          >
            <h3>Creating new event</h3>
            <Form
              listingId={listingId}
              setItems={setItems}
              accountHandle={accountHandle}
              authenticityToken={authenticityToken}
              close={() => setShowForm(false)}
              success={afterCreate}
            />
          </div>
        ) : (
          <button
            className="button btn-primary"
            onClick={() => setShowForm(true)}
          >
            New event
          </button>
        )}

        <div style={{ margin: "0.5em" }}>
          <hr />
          Sort by:
          <div className="btn-group" role="group" aria-label="...">
            <button
              className="button btn-default"
              onClick={() => setSortBy("created_at")}
            >
              created at
            </button>
            <button
              className="button btn-default"
              onClick={() => setSortBy("occurs_at")}
            >
              event date-time
            </button>
          </div>
        </div>

        <List
          events={items}
          listingId={listingId}
          accountHandle={accountHandle}
          deleteEvent={deleteEvent}
        />
        <hr />
      </div>
    </div>
  );
}
