import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    if (window.webkit && window.webkit.messageHandlers.getDeviceInfo) {
      window.pushDeviceInfo = this.pushDeviceInfo
      this.pushDeviceInfo(window.webkit.messageHandlers.getDeviceInfo.postMessage(null))
    }
    if (window.android && window.android.getDeviceInfo) {
      this.pushDeviceInfo(window.android.getDeviceInfo())
    }
  }

  // {
  //   "platform": "android",
  //   "version": 1.0.6,
  //   "build": 51,
  //   "manufacturer": "Google",
  //   "model": "sdk_gphone64_arm64",
  //   "device": "emu64a",
  //   "push_token": "czZGxkLORf-0OcCxXNnNCY:APA91bGKKjjxwq1PpG32tXghYjAJ46UNXhJ5pCIY2itMceaCNuNTO5eLsxupnXyhj32x7w-SgfaVRkBYhH4gG_1_DdLdDq7sbcr_YZ7GoYGG2-wq4jV9W84LV2J7TWt7hAAQbHYtsdD2",
  //   "firebase_push_token": "czZGxkLORf-0OcCxXNnNCY:APA91bGKKjjxwq1PpG32tXghYjAJ46UNXhJ5pCIY2itMceaCNuNTO5eLsxupnXyhj32x7w-SgfaVRkBYhH4gG_1_DdLdDq7sbcr_YZ7GoYGG2-wq4jV9W84LV2J7TWt7hAAQbHYtsdD2"
  //   }
  pushDeviceInfo = (data) => {
    const json_data = JSON.parse(data)
    if (window.deviceLoaded == null) {
      const params = new URLSearchParams(json_data).toString()
      this.element.setAttribute('href', this.element.href + '?' + params)
      this.element.click()
      window.deviceLoaded = true
    }
  }
}

