import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['card', 'errors', 'token', 'submit']

  connect() {
    this._load()
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.script.remove()
      $(this.element).off("submit", this.submit)
    }
  }

  _load() {
    if (!this.hasCardTarget) return
    this.script = document.createElement('script');
    this.script.onload = () => {
      this.start()
    }
    this.script.src = 'https://js.stripe.com/v3/'
    document.head.appendChild(this.script);
    $(this.element).on("submit", this.submit)

  }

  start = () => {
    if (!this.hasCardTarget) return
    let style = {
      base: {
        fontSize: '1rem',
        fontWeight: '300'
      }
    }

    this.stripe = Stripe(this.data.get('key'))
    this.elements = this.stripe.elements()
    this.card = this.elements.create('card', { style: style })
    this.card.mount(this.cardTarget)
    this.card.on('change', this._hideError)
  }

  submit = (event) => {
    if (!this.hasCardTarget || this.tokenTarget.value) return
    event.preventDefault()
    event.stopPropagation()
    this._hideError()
    this._disableSubmit()
    this.stripe.createToken(this.card).then(result => {
      if (result.error) {
        this._showError(result.error.message)
        this._enableSubmit()
      } else {

        this._addToken(result.token);
        this.element.submit()
      }
    })
  }

  _addToken = (token) => {
    this.tokenTarget.value = token.id
  }

  _showError = (text) => {
    this.errorsTarget.textContent = text
    this.errorsTarget.style.display = 'block'
  }

  _hideError = () => {
    this.errorsTarget.style.display = 'none'
  }

  _disableSubmit() {
    this.submitTarget.disabled = true
    this.submitTarget.dataset.enableWith = this.submitTarget.innerHTML
    this.submitTarget.innerHTML = this.submitTarget.dataset.disableWith
  }

  _enableSubmit() {
    this.submitTarget.disabled = false
    this.submitTarget.innerHTML = this.submitTarget.dataset.enableWith
  }

}
