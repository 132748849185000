import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Summary from "./summary";
import Inventory from "./inventory";

function WarehouseReport({ match, history }) {
  const [options, setOptions] = useState([]);
  const [selectedId, setSelectedId] = useState(match.params.id || -1);

  useEffect(() => {
    fetch(
      `//${window.location.host}/${match.params.account_id}/warehouses.json`
    )
      .then(response => response.json())
      .then(json => {
        setOptions(json.warehouses);
      });
  }, []);

  useEffect(() => {
    if (selectedId === "-1" || selectedId === match.params.id) return;
    history.push(
      `/${match.params.account_id}/pages/warehouse_report/${selectedId}`
    );
  }, [selectedId]);

  return (
    <div id="top-of-page">
      <select
        value={selectedId}
        onChange={e => {
          setSelectedId(e.target.value);
        }}
      >
        <option value="-1">Select a warehouse to view report</option>
        {options.map(option => (
          <option value={option.id} key={option.id}>
            {option.name}
          </option>
        ))}
      </select>

      <div id={`inventory_page_top`}>
        {selectedId > -1 ? (
          <>
            <Summary warehouseId={selectedId} />
            <Inventory warehouseId={selectedId} />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default withRouter(WarehouseReport);
